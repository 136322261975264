import { GetServerSideProps, GetServerSidePropsResult, NextPage } from "next";
import { useEffect } from "react";

interface PageProps {
    redirectUri: string;
}

const LoginPage: NextPage<PageProps> = ({ redirectUri }) => {
    useEffect(() => {
        window.location.href = `${process.env.API_URL}/auth/login?client_id=${
            process.env.CLIENT_ID
        }&scope=all&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code`;
    }, []);

    return null;
};

export default LoginPage;

export const getServerSideProps: GetServerSideProps = async (context): Promise<GetServerSidePropsResult<PageProps>> => {
    const redirectUri = process.env.AUTHORIZATION_REDIRECT_URI;

    if (typeof redirectUri !== "string") {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            redirectUri,
        },
    };
};
